@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes moveInFromLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes moveInFromLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@-webkit-keyframes moveOutToLeft {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}

@keyframes moveOutToLeft {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}

@-webkit-keyframes moveInFromRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes moveInFromRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@-webkit-keyframes moveOutToRight {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(50%);
        transform: translateX(50%);
    }
}

@keyframes moveOutToRight {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(50%);
        transform: translateX(50%);
    }
}

.sceneElement {
    -webkit-animation-duration: .4s;
    animation-duration: .4s;
    transition-timing-function: ease-in;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.is-exiting .sceneElement {
    transition-timing-function: ease-out;
}

#main[data-transition=fade] .sceneElement {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

#main[data-transition=fade].is-exiting .sceneElement {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

#main[data-transition=moveleft] .sceneElement {
    -webkit-animation-name: moveInFromLeft;
    animation-name: moveInFromLeft;
}

#main[data-transition=moveleft].is-exiting .sceneElement {
    -webkit-animation-name: moveOutToRight;
    animation-name: moveOutToRight;
}

#main[data-transition=moveright] .sceneElement {
    -webkit-animation-name: moveInFromRight;
    animation-name: moveInFromRight;
}

#main[data-transition=moveright].is-exiting .sceneElement {
    -webkit-animation-name: moveOutToLeft;
    animation-name: moveOutToLeft;
}
